import React, { useState, useEffect } from "react"
import Icon from "../icon/icon"
import "./share.css"
import copyToClipboard from "../../utils/copyToClipboard"

function Share({ url = "", imgUrl = "" }) {
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 1500)
    }
  }, [copied])

  return (
    <>
      <aside className="share">
        <button className="share-toggle" onClick={() => setOpen(!open)}>
          <Icon type="share" className="share-icon" />
          share options
        </button>
        <ul className={`share-options ${open && "is-active"}`}>
          <li className="share-option">
            <button
              className="share-button"
              onClick={() => {
                copyToClipboard(url)
                setCopied(true)
                setOpen(false)
              }}
            >
              <Icon type="copy" className="share-option-icon" />
              Copy link
            </button>
          </li>
          <li className="share-option">
            <a href={imgUrl} download className="share-button">
              <Icon type="download" className="share-option-icon" />
              Download image
            </a>
          </li>
        </ul>
      </aside>
      <button
        className={`share-overlay ${open && "is-active"}`}
        onClick={() => setOpen(!open)}
      />
      <p className={`share-feedback ${copied && "is-active"}`}>
        Copied to clipboard
      </p>
    </>
  )
}

export default Share
