import React, { useRef } from "react"
import "./portrait.css"
import Image from "../image"
import { useStaticQuery, graphql } from "gatsby"
import Share from "../share/share"

function Portrait({ name, fullname, nickname, img }) {
  const container = useRef(null)

  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "jpg" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  return (
    <figure id={`${name}`} className="portrait" ref={container}>
      <Image imgName={img} className="portrait-image" />
      <figcaption className="portrait-caption">
        <p>
          <span className="portrait-name">{nickname}</span>
          {fullname && <span className="portrait-fullname">{fullname}</span>}
        </p>
        {data.allFile.edges.map(item =>
          img.includes(item.node.name) ? (
            <Share
              key={`${item.node.name}`}
              url={`https://kabayan.hornebom.com/closeup/#${name}`}
              imgUrl={item.node.publicURL}
            />
          ) : null
        )}
      </figcaption>
    </figure>
  )
}

export default Portrait
