import React from "react"
import BackButton from "../components/backButton/backButton"

import Layout from "../components/layout"
import SEO from "../components/seo"
import friends from "../config/friends"
import Portrait from "../components/portrait/portrait"

const CloseupPage = () => (
  <Layout>
    <SEO title="Kabayan closeups" />
    <BackButton />
    {friends.map((friend, i) => (
      <Portrait
        name={friend.name}
        img={friend.img}
        key={i.toString()}
        fullname={friend.fullname}
        nickname={friend.nickname}
      />
    ))}
  </Layout>
)

export default CloseupPage
