import React from "react"

function Share({ className }) {
  return (
    <svg viewBox="0 0 424.96 424.96" className={className}>
      <path
        d="M340.48,300.587c-16.213,0-30.827,6.293-41.92,16.427l-152-88.64c1.173-4.8,1.92-9.813,1.92-14.933
			s-0.747-10.133-1.92-14.933l150.4-87.787c11.413,10.667,26.667,17.28,43.52,17.28c35.307,0,64-28.693,64-64
			c0-35.307-28.693-64-64-64c-35.307,0-64,28.693-64,64c0,5.12,0.747,10.133,1.92,14.933L128,166.72
			c-11.413-10.667-26.667-17.28-43.52-17.28c-35.307,0-64,28.693-64,64c0,35.307,28.693,64,64,64c16.853,0,32.107-6.613,43.52-17.28
			l152,88.64c-1.067,4.48-1.707,9.173-1.707,13.973c0,34.347,27.84,62.187,62.187,62.187s62.187-27.84,62.187-62.187
			S374.827,300.587,340.48,300.587z"
      />
    </svg>
  )
}

export default Share
