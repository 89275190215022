import React from "react"
import ArrowLeft from "./arrowLeft"
import Download from "./download"
import Share from "./Share"
import Copy from "./copy"

const types = {
  arrowLeft: ArrowLeft,
  download: Download,
  share: Share,
  copy: Copy,
}

function Icon({ type, className }) {
  const Comp = types[type]
  return <Comp className={className} />
}

export default Icon
