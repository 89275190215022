import React from "react"
import "./backButton.css"
import { Link } from "gatsby"
import Icon from "../icon/icon"

function BackButton() {
  return (
    <Link to="/" className="backButton">
      <Icon type="arrowLeft" className="backButton-arrow" />
      Overview
    </Link>
  )
}

export default BackButton
